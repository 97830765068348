* {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}

canvas {
  background: rgb(172, 209, 240);
  border: solid 2px rgb(152, 185, 212);
}
body {
  height: 100vh;
  width: 100vw;
}

#container {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  position: fixed;
}

#score {
  position: fixed;
  top: 20px;
  left: 20px;

  font-size: 50px;
  font-weight: bold;
  
}